.ant-menu {
	width: 256px;
	position: fixed;
	top: 0;
	border-right: 0.5px solid #12121229;
	height: 100vh;
	background: #fff;
}

.ant-menu-inline-collapsed {
	width: 64px;

	.ant-menu-title-content {
		display: inline-flex;
		align-items: center;
	}
}

.ant-menu-item {
	text-align: start;
	padding-left: 12px !important;
	font-family: "Inter", sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0em;
	color: #121212e5;
	margin: 8px 12px !important;
	width: calc(100% - 24px) !important;
	border-radius: 8px;
}

.ant-menu-item:not(.ant-menu-item-selected):hover {
	background: #1212120a !important;
	border-radius: 8px !important;
}

.ant-menu-item:not(.ant-menu-item-selected):active {
	background: #1212120a !important;
	border-radius: 8px !important;
}

.ant-menu-item-selected {
	border-radius: 8px !important;
	background: #be984114 !important;

	& span {
		color: #be9841;
	}

	& svg {
		& path {
			fill: #be9841;
		}
	}
}

.ant-menu-title-content {
	text-overflow: unset !important;
	display: contents;
	& svg {
		margin-right: 12px !important;
	}
}

&:hover {
	border: unset;
}

.ant-picker {
	width: 174px;
}

.ant-picker-focused {
	border-color: #be9841 !important;
	box-shadow: 0px 0px 0px 2px #ccaf6b29 !important;
}

.ant-picker:hover {
	border-color: #be9841 !important;
	box-shadow: 0px 0px 0px 2px #ccaf6b29 !important;
}

.ant-tooltip-inner {
	display: flex;
	align-items: center;
	gap: 4px;

	& svg {
		& path {
			fill: white;
		}
	}
}
